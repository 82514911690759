import React, { lazy, Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import RestrictedAccess from '../../containers/RestrictedAccess';
import Spinner from '../../components/Spinner';
import TitleComponent from '../../components/TitleComponent';
import ProtectedRoutes from '../../containers/ProtectedRoutes';
import Apps from '../../pages/Apps';
import EmergencyAlerts from '../../pages/Apps/EmergencyAlerts';
import Html5 from '../../pages/Apps/Html5';
import LiveStream from '../../pages/Apps/LiveStream';
import PowerBi from '../../pages/Apps/PowerBi';
import SevereWeather from '../../pages/Apps/SevereWeather';
import Ticker from '../../pages/Apps/Ticker';
import Weather from '../../pages/Apps/Weather';
import Youtube from '../../pages/Apps/Youtube';
import DeviceGroupInfo from '../../pages/DeviceGroupInfo';
import Devices from '../../pages/Devices';
import DevicesGroups from '../../pages/DevicesGroups';
import FileManager from '../../pages/FileManager';
import Home from '../../pages/Home';
import Libraries from '../../pages/Libraries';
import LibraryCategories from '../../pages/LibraryCategories';
import Library from '../../pages/Library';
import Login from '../../pages/Login';
import MyAccount from '../../pages/MyAccount';
import Playlist from '../../pages/Playlist';
import Playlists from '../../pages/Playlists';
import Statistic from '../../pages/Statistic';
import UserGroupInfo from '../../pages/UserGroupInfo';
import UserProfileComponent from '../../pages/UserProfileComponent';
import Users from '../../pages/Users';
import UsersGroups from '../../pages/UsersGroups';
import Templates from '../../pages/Templates';
import { getUserData } from '../../store/actions/userData';
import useDocumentTitle from '../../utils/hooks/useDocumentTitle';
import config from '../config';
import GoogleSheets from '../../pages/Apps/GoogleSheets';
import Rss from '../../pages/Apps/Rss';
import Layout from '../../pages/Layout';
import GoogleDocs from '../../pages/Apps/GoogleDocs';
import GoogleCalendar from '../../pages/Apps/GoogleCalendar';
import GoogleSlides from '../../pages/Apps/GoogleSlides';
import { UserRole } from '../../enums/userRole.enum';
import Canva from '../../pages/Apps/Canva';
// import Twitter from '../../pages/Apps/Twitter';
import Facebook from '../../pages/Apps/Facebook';
import Instagram from '../../pages/Apps/Instagram';
import GlobalState from '../../store/reducers/globalState.interface';
import MSTeams from '../../pages/Apps/MSTeams';
import { roleRedirectionRoute } from '../../constants/roleRedirectionRoutes';
import SignUp from '../../pages/Auth/SignUpPassword';
import SignUpEmail from '../../pages/Auth/SignUpEmail';
import Profile from '../../pages/Auth/Profile';
import {
  checkSignIn,
  getAccessTokenOrSignIn,
  loginRedirectLocation,
} from '../../utils/auth0';
import SimpleLayout from '../../pages/Layout/simpleLayout';
import SignIn from '../../pages/Auth/SignIn';
import CanvaSignIn from '../../pages/Auth/LoginCanva';
import LibraryWorkplaceCategories from '../../pages/Workplace';
import Company from '../../pages/Company';
import ForgotPassword from '../../pages/Auth/ForgotPassword';
import NotFound from '../../pages/Errors/NotFound';
import QrCode from '../../pages/Apps/QrCode';
import CountUpTimer from '../../pages/Apps/CountUpTimer';
import CountDownTimer from '../../pages/Apps/CountDownTimer';

import DaysSafe from '../../pages/Apps/DaysSafe';
import { readFromQueryString } from '../../utils/queryParams';
import AppAnalytics from '../../pages/AdminPanel/Analytics/index';

const AdminRoutes = lazy(() => import('./AdminRoutes'));

// Add titles

const LoginComponent = useDocumentTitle({
  ChildComponent: Login,
  title: 'Login',
});

const ForgotPasswordComponent = useDocumentTitle({
  ChildComponent: ForgotPassword,
  title: 'Forgot Password',
  isAuthLayout: true,
});

const SignUpComponent = useDocumentTitle({
  ChildComponent: SignUp,
  title: 'Sign up',
  isAuthLayout: true,
});

const SignUpEmailComponent = useDocumentTitle({
  ChildComponent: SignUpEmail,
  title: 'Sign up',
  isAuthLayout: true,
});
const CanvaSignInComponent = useDocumentTitle({
  ChildComponent: CanvaSignIn,
  title: 'Sign in for Canva',
  isAuthLayout: true,
});

const SignInComponent = useDocumentTitle({
  ChildComponent: SignIn,
  title: 'Sign in',
  isAuthLayout: true,
});

const ProfileComponent = useDocumentTitle({
  ChildComponent: Profile,
  title: 'Complete Your Profile',
  isAuthLayout: true,
});

const UsersComponent = useDocumentTitle({
  ChildComponent: Users,
  title: 'Users',
});

const DevicesComponent = useDocumentTitle({
  ChildComponent: Devices,
  title: 'Devices',
});

const DeviceGroupInfoComponent = useDocumentTitle({
  ChildComponent: DeviceGroupInfo,
  title: 'Devices Group Info',
});

const UserGroupsComponent = useDocumentTitle({
  ChildComponent: UsersGroups,
  title: 'User Groups',
});

const UserGroupsInfoComponent = useDocumentTitle({
  ChildComponent: UserGroupInfo,
  title: 'User Groups Info',
});

const DeviceGroupsComponent = useDocumentTitle({
  ChildComponent: DevicesGroups,
  title: 'Device Groups',
});

const PlaylistsComponent = useDocumentTitle({
  ChildComponent: Playlists,
  title: 'Playlists',
});

const PlaylistComponent = useDocumentTitle({
  ChildComponent: Playlist,
  title: 'Playlist',
});

const AppsComponent = useDocumentTitle({
  ChildComponent: Apps,
  title: 'Apps',
});

const TemplatesComponent = useDocumentTitle({
  ChildComponent: Templates,
  title: 'Templates',
});

const MyAccountComponent = useDocumentTitle({
  ChildComponent: MyAccount,
  title: 'My Account',
});

const TickerComponent = useDocumentTitle({
  ChildComponent: Ticker,
  title: 'Tickers',
});

const GoogleSheetsComponent = useDocumentTitle({
  ChildComponent: GoogleSheets,
  title: 'GoogleSheets',
});

const GoogleSlideComponent = useDocumentTitle({
  ChildComponent: GoogleSlides,
  title: 'GoogleSlides',
});

const CanvaComponent = useDocumentTitle({
  ChildComponent: Canva,
  title: 'Canva',
});

// Disabled Twitter Until we get the new API
// const TwitterComponent = useDocumentTitle({
//   ChildComponent: Twitter,
//   title: 'Twitter',
// });

const FacebookComponent = useDocumentTitle({
  ChildComponent: Facebook,
  title: 'Facebook',
});

const InstagramComponent = useDocumentTitle({
  ChildComponent: Instagram,
  title: 'Instagram',
});

const MsTeamsComponent = useDocumentTitle({
  ChildComponent: MSTeams,
  title: 'Microsoft Teams',
});

const GoogleDocComponent = useDocumentTitle({
  ChildComponent: GoogleDocs,
  title: 'GoogleDocs',
});

const GoogleCalendarComponent = useDocumentTitle({
  ChildComponent: GoogleCalendar,
  title: 'GoogleCalendar',
});

const CountUpTimerComponent = useDocumentTitle({
  ChildComponent: CountUpTimer,
  title: 'Count Up Timer',
});

const CountDownTimerComponent = useDocumentTitle({
  ChildComponent: CountDownTimer,
  title: 'Count Down Timer',
});

const DaysSafeComponent = useDocumentTitle({
  ChildComponent: DaysSafe,
  title: 'Injury Free Counter',
});

const RSSComponent = useDocumentTitle({
  ChildComponent: Rss,
  title: 'Rss',
});

const WeatherComponent = useDocumentTitle({
  ChildComponent: Weather,
  title: 'Weather',
});

const EmergencyAlertsComponent = useDocumentTitle({
  ChildComponent: EmergencyAlerts,
  title: 'Emergency Alerts',
});

const Html5Component = useDocumentTitle({
  ChildComponent: Html5,
  title: 'Html',
});

const LiveStreamComponent = useDocumentTitle({
  ChildComponent: LiveStream,
  title: 'LiveStream',
});

const YoutubeComponent = useDocumentTitle({
  ChildComponent: Youtube,
  title: 'Youtube',
});

const PowerBiComponent = useDocumentTitle({
  ChildComponent: PowerBi,
  title: 'PowerBi',
});

const SevereWeatherComponent = useDocumentTitle({
  ChildComponent: SevereWeather,
  title: 'SevereWeather',
});

const QrCodeComponent = useDocumentTitle({
  ChildComponent: QrCode,
  title: 'QR Code',
});

const StatisticComponent = useDocumentTitle({
  ChildComponent: Statistic,
  title: 'Statistic',
});

const LibraryCategoriesComponent = useDocumentTitle({
  ChildComponent: LibraryCategories,
  title: 'Library Categories',
});

const subCategoryLibrariesComponent = useDocumentTitle({
  ChildComponent: Libraries,
  title: 'Library Categories',
});

const LibraryCategoriesWorkplaceComponent = useDocumentTitle({
  ChildComponent: LibraryWorkplaceCategories,
  title: 'Library Categories',
});

const LibrariesComponent = useDocumentTitle({
  ChildComponent: Libraries,
  title: 'Libraries',
});

const LibraryComponent = useDocumentTitle({
  ChildComponent: Library,
  title: 'Library',
});

const CompanyComponent = useDocumentTitle({
  ChildComponent: Company,
  title: 'Library',
});
const AppAnalyticsComoonent = useDocumentTitle({
  ChildComponent: AppAnalytics,
  title: 'App Analytics',
});

export default () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const safeEmailsListForMsTeams = ['msnow@indigoworkplace.com'];

  const user = useSelector((state: GlobalState) => {
    return state.userData.user;
  });

  const { roles, onboarding, isTrial } = user;
  const currentRoute = location.pathname;

  const checkUserExists = async () => {
    const unprotectedRoutes: string[] = [
      config.signin.route,
      config.signupPassword.route,
      config.signupEmail.route,
      config.login.route,
      config.forgotPassword.route,
      config.CanvaSignin.route,
      // config.home.route,
    ];

    if (!unprotectedRoutes.includes(currentRoute)) {
      const userExists = await checkSignIn();
      if (userExists) {
        dispatch(getUserData());
      } else {
        getAccessTokenOrSignIn();
      }
    }
  };

  const checkRedirection = async () => {
    const isSignedIn = await checkSignIn();

    // debugger;
    if (currentRoute === config.home.route && !isSignedIn) {
      // history.push(config.signin.route);
      await loginRedirectLocation();
    }

    // if (isSignedIn && currentRoute === config.home.route) {
    //   window.location.reload();
    // }

    if (
      (currentRoute === config.signupPassword.route ||
        currentRoute === config.signin.route ||
        currentRoute === config.signupEmail.route ||
        currentRoute === config.forgotPassword.route) &&
      isSignedIn
    ) {
      history.push(config.home.route);
    }
  };

  const checkRedirectionForCanva = async () => {
    const isSignedIn = await checkSignIn();
    const query = window.location.search;
    const canvaId = readFromQueryString(query, 'canvaId');
    const state = readFromQueryString(query, 'state');
    const nonce = readFromQueryString(query, 'nonce');

    if (currentRoute === config.CanvaSignin.route && isSignedIn) {
      if (user?.email === '') dispatch(getUserData());

      if (user?.email !== '') {
        window.location.href = `${
          process.env.REACT_APP_API_URL
        }/canva/redirect?state=${encodeURIComponent(
          state as string,
        )}&canvaId=${encodeURIComponent(
          canvaId as string,
        )}&email=${encodeURIComponent(
          user?.email as string,
        )}&nonce=${encodeURIComponent(nonce as string)}`;
      }
    }
    if (!canvaId && !state) {
      history.push(config.home.route);
    }
  };

  useEffect(() => {
    checkUserExists();
  }, []);

  useEffect(() => {
    checkRedirection();
  }, [currentRoute]);

  useEffect(() => {
    if (currentRoute === config.CanvaSignin.route) {
      checkRedirectionForCanva();
    }
  }, [user]);

  useEffect(() => {
    const onOnboardingRoute = currentRoute === config.completeProfile.route;
    if (
      onboarding &&
      !onboarding?.isProfileCompleted &&
      roles.includes(UserRole.AccountOwner) &&
      isTrial &&
      !onOnboardingRoute
    ) {
      history.push(config.completeProfile.route);
    }
  }, [onboarding, currentRoute]);

  function HomeRoute() {
    useEffect(() => {
      const cantBeStuckHere = setTimeout(() => {
        window.location.reload();
      }, 5000);

      return () => {
        clearTimeout(cantBeStuckHere);
      };
    }, []);

    if (!roles.length) {
      return <Spinner />;
    }
    // const loginAsUserName = window.localStorage.getItem('loginAsUserName');
    const redirectionRoute = Object.entries(roleRedirectionRoute).find(
      ([role]) => roles.includes(role as UserRole),
    );
    if (redirectionRoute) {
      return <Redirect to={redirectionRoute[1]} />;
    }
    return (
      <Layout>
        <Home />
      </Layout>
    );
  }

  return (
    <Switch>
      <Route exact path={config.login.route} component={LoginComponent} />
      <Route exact path={config.home.route} render={() => <HomeRoute />} />
      <Route
        exact
        path={config.signupPassword.route}
        component={SignUpComponent}
      />
      <Route
        exact
        path={config.signupEmail.route}
        component={SignUpEmailComponent}
      />
      <Route exact path={config.signin.route} component={SignInComponent} />

      <Route
        exact
        path={config.CanvaSignin.route}
        component={CanvaSignInComponent}
      />
      <Route
        exact
        path={config.forgotPassword.route}
        component={ForgotPasswordComponent}
      />

      <ProtectedRoutes>
        <Route
          exact
          path={config.adminAppAnalytics.route}
          component={AppAnalyticsComoonent}
        />
        <Route
          exact
          path={config.completeProfile.route}
          render={() => (
            <SimpleLayout>
              <ProfileComponent />
            </SimpleLayout>
          )}
        />
        <Route
          exact
          path={config.statistic.route}
          render={() => (
            <RestrictedAccess fallback={<Redirect to="/playlists" />}>
              <StatisticComponent />
            </RestrictedAccess>
          )}
        />
        <Route
          path={config.adminHome.route}
          render={() => (
            <Suspense fallback={<Spinner />}>
              <AdminRoutes />
            </Suspense>
          )}
        />
        <Route
          exact
          path={config.users.route}
          render={() => <UsersComponent />}
        />
        <Route
          exact
          path={config.user.route}
          render={() => (
            <Layout>
              <UserProfileComponent />
            </Layout>
          )}
        />
        <Route
          exact
          path={config.customer.route}
          render={() => (
            <Layout>
              <UserProfileComponent isCustomer />
            </Layout>
          )}
        />
        <Route exact path={config.company.route} component={CompanyComponent} />
        <Route
          exact
          path={config.usersGroups.route}
          render={() => (
            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.Employee,
                UserRole.IndigoAdmin,
                UserRole.Editor,
              ]}
              fallback={<Redirect to="/playlists" />}
            >
              <UserGroupsComponent />
            </RestrictedAccess>
          )}
        />
        <Route
          exact
          path={config.usersGroup.route}
          component={UserGroupsInfoComponent}
        />
        <Route exact path={config.devices.route} component={DevicesComponent} />
        <Route
          exact
          path={config.playlists.route}
          component={PlaylistsComponent}
        />
        <Route
          exact
          path={config.playlist.route}
          component={PlaylistComponent}
        />
        <Route
          exact
          path={config.myAccount.route}
          component={MyAccountComponent}
        />
        <Route
          exact
          path={config.devicesGroups.route}
          render={() => (
            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.IndigoAdmin,
                UserRole.Editor,
                UserRole.Manager,
              ]}
              fallback={<Redirect to="/playlists" />}
            >
              <DeviceGroupsComponent />
            </RestrictedAccess>
          )}
        />
        <Route
          exact
          path={config.devicesGroup.route}
          component={DeviceGroupInfoComponent}
        />
        <Route
          exact
          path={config.tickers.route}
          render={() => (
            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.IndigoAdmin,
                UserRole.Editor,
                UserRole.Manager,
                UserRole.Employee,
              ]}
              isTrial={isTrial}
              fallback={<NotFound />}
            >
              <TickerComponent />
            </RestrictedAccess>
          )}
        />
        <Route exact path={config.apps.route} component={AppsComponent} />
        <Route
          exact
          path={config.emergencyAlerts.route}
          component={EmergencyAlertsComponent}
        />
        <Route
          exact
          path={config.powerBi.route}
          render={() => (
            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.IndigoAdmin,
                UserRole.Editor,
                UserRole.Manager,
              ]}
              isTrial={isTrial}
              fallback={<NotFound />}
            >
              <PowerBiComponent />
            </RestrictedAccess>
          )}
        />
        <Route exact path={config.youtube.route} component={YoutubeComponent} />
        <Route exact path={config.html5.route} component={Html5Component} />
        <Route
          exact
          path={config.LiveStream.route}
          component={LiveStreamComponent}
        />
        <Route exact path={config.rss.route} component={RSSComponent} />
        <Route exact path={config.weather.route} component={WeatherComponent} />
        <Route
          exact
          path={config.twitter.route}
          render={() => (
            // Disabled Twitter Until we get the new API
            // <RestrictedAccess
            //   allowedRoles={[
            //     UserRole.SuperAdmin,
            //     UserRole.Admin,
            //     UserRole.AccountOwner,
            //     UserRole.IndigoAdmin,
            //     UserRole.Editor,
            //     UserRole.Manager,
            //     UserRole.Employee,
            //   ]}
            //   fallback={<NotFound />}
            // >
            //   <TwitterComponent />
            // </RestrictedAccess>
            <NotFound />
          )}
        />

        <Route
          exact
          path={config.facebook.route}
          render={() => (
            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.IndigoAdmin,
                UserRole.Editor,
                UserRole.Manager,
                UserRole.Employee,
              ]}
              fallback={<NotFound />}
            >
              <FacebookComponent />
            </RestrictedAccess>
          )}
        />

        <Route
          exact
          path={config.instagram.route}
          render={() => (
            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.IndigoAdmin,
                UserRole.Editor,
                UserRole.Manager,
                UserRole.Employee,
              ]}
              fallback={<NotFound />}
            >
              <InstagramComponent />
            </RestrictedAccess>
          )}
        />

        <Route
          exact
          path={config.MsTeams.route}
          render={() => (
            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.IndigoAdmin,
                UserRole.Editor,
                UserRole.Manager,
                UserRole.Employee,
              ]}
              fallback={<NotFound />}
              lockedApp={
                !(
                  process.env.REACT_APP_ENV === 'prod' &&
                  safeEmailsListForMsTeams.find((email) => email === user.email)
                )
              }
            >
              <MsTeamsComponent />
            </RestrictedAccess>
          )}
        />

        <Route
          exact
          path={config.googleSheets.route}
          render={() => (
            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.IndigoAdmin,
                UserRole.Editor,
                UserRole.Manager,
                UserRole.Employee,
              ]}
              fallback={<NotFound />}
            >
              <GoogleSheetsComponent />
            </RestrictedAccess>
          )}
        />

        <Route
          exact
          path={config.qrCode.route}
          render={() => (
            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.IndigoAdmin,
                UserRole.Editor,
                UserRole.Manager,
                UserRole.Employee,
              ]}
              fallback={<NotFound />}
            >
              <QrCodeComponent />
            </RestrictedAccess>
          )}
        />
        <Route
          exact
          path={config.googleSlides.route}
          render={() => (
            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.IndigoAdmin,
                UserRole.Editor,
                UserRole.Manager,
                UserRole.Employee,
              ]}
              fallback={<NotFound />}
            >
              <GoogleSlideComponent />
            </RestrictedAccess>
          )}
        />
        <Route
          exact
          path={config.googleDocs.route}
          render={() => (
            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.IndigoAdmin,
                UserRole.Editor,
                UserRole.Manager,
                UserRole.Employee,
              ]}
              fallback={<NotFound />}
            >
              <GoogleDocComponent />
            </RestrictedAccess>
          )}
        />
        <Route
          exact
          path={config.googleCalendar.route}
          render={() => (
            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.IndigoAdmin,
                UserRole.Editor,
                UserRole.Manager,
                UserRole.Employee,
              ]}
              fallback={<NotFound />}
            >
              <GoogleCalendarComponent />
            </RestrictedAccess>
          )}
        />

        <Route
          exact
          path={config.CountDownTimer.route}
          render={() => (
            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.IndigoAdmin,
                UserRole.Editor,
                UserRole.Manager,
                UserRole.Employee,
              ]}
              fallback={<NotFound />}
            >
              <CountDownTimerComponent />
            </RestrictedAccess>
          )}
        />

        <Route
          exact
          path={config.DaysSafe.route}
          render={() => (
            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.IndigoAdmin,
                UserRole.Editor,
                UserRole.Manager,
                UserRole.Employee,
              ]}
              fallback={<NotFound />}
            >
              <DaysSafeComponent />
            </RestrictedAccess>
          )}
        />

        <Route
          exact
          path={config.CountUpTimer.route}
          render={() => (
            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.IndigoAdmin,
                UserRole.Editor,
                UserRole.Manager,
                UserRole.Employee,
              ]}
              fallback={<NotFound />}
            >
              <CountUpTimerComponent />
            </RestrictedAccess>
          )}
        />
        <Route
          exact
          path={config.canva.route}
          render={() => (
            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.IndigoAdmin,
                UserRole.Editor,
                UserRole.Manager,
                UserRole.Employee,
              ]}
              fallback={<NotFound />}
            >
              <CanvaComponent />
            </RestrictedAccess>
          )}
        />
        <Route
          exact
          path={config.canvaSchedule.route}
          render={() => (
            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.IndigoAdmin,
                UserRole.Editor,
                UserRole.Manager,
                UserRole.Employee,
              ]}
              fallback={<NotFound />}
            >
              <CanvaComponent />
            </RestrictedAccess>
          )}
        />
        <Route
          exact
          path={config.severeWeather.route}
          render={() => (
            <RestrictedAccess
              allowedRoles={[
                UserRole.SuperAdmin,
                UserRole.Admin,
                UserRole.AccountOwner,
                UserRole.IndigoAdmin,
                UserRole.Editor,
                UserRole.Manager,
                UserRole.Employee,
              ]}
              isTrial={isTrial}
              fallback={<NotFound />}
            >
              <SevereWeatherComponent />
            </RestrictedAccess>
          )}
        />

        {/* Libraries */}
        <Route
          exact
          path={config.indigoLibraryCategories.route}
          component={LibraryCategoriesComponent}
        />
        <Route
          exact
          path={config.indigoSubCategoriesLibraries.route}
          component={subCategoryLibrariesComponent}
        />
        <Route
          exact
          path={config.indigoLibraryWorkplace.route}
          component={LibraryCategoriesWorkplaceComponent}
        />
        <Route
          exact
          path={config.indigoLibraries.route}
          component={LibrariesComponent}
        />
        <Route
          exact
          path={config.indigoLibrary.route}
          component={LibraryComponent}
        />
        <Route
          exact
          path={config.indigoLibraryUnderSubCategory.route}
          component={LibraryComponent}
        />

        <Route
          exact
          path={config.templates.route}
          render={() => <TemplatesComponent />}
        />
        <Route key="private-content" exact path={config.contents.route}>
          <TitleComponent title="File Manager" />
          <Layout>
            <FileManager />
          </Layout>
        </Route>
        <Route key="shared-content" exact path={config.sharedContents.route}>
          <TitleComponent title="File Manager" />
          <Layout>
            <FileManager isPublicState />
          </Layout>
        </Route>
      </ProtectedRoutes>
    </Switch>
  );
};
